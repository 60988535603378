@use '@angular/material' as mat;

@import '../../../node_modules/ngx-bootstrap/datepicker/bs-datepicker.css';
@import '../../../node_modules/@angular/material/prebuilt-themes/indigo-pink.css';
@import '../../../node_modules/bootstrap/scss/bootstrap.scss';
@import '../../../node_modules/intl-tel-input/build/css/intlTelInput.css';
@import '../../../node_modules/@splidejs/splide/dist/css/splide.min.css';
@import "variables";

/* fonts */
@font-face {
  font-family: 'Unbounded';
  src: url('assets/fonts/Unbounded/Unbounded-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Unbounded';
  src: url('assets/fonts/Unbounded/Unbounded-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  background-color: #000000;
  font-size: 14px;
  font-family: 'Unbounded', 'Manrope', sans-serif;
  color: #fff;
}

.main-container {
  max-width: 1440px;
  background-color: $color-dark-purple;
  margin: 0 auto;
  padding-top: 60px;
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

h1, h2, h3, h4, h5, h6 {
  font-size: 14px;
  font-family: 'Unbounded', 'Manrope', sans-serif;
  font-weight: 900;
}

p {
  font-family: 'Unbounded', serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.32px;
}

.header-section {
  position: fixed;
  z-index: 1006;
  right: 0;
  left: 0;
  top: 0;
  background-color: $color-dark;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
}

.body-section {
  position: relative;
  max-width: $inner-width;
  margin: 20px auto 20px auto;
  padding: $inner-padding;
  width: 100%;
  flex: 1;
}

.csn-btn {

  padding: 8px 32px;
  cursor: pointer;
  color: #FFF;
  font-family: 'Unbounded', 'Manrope', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 900;
  line-height: 24px; /* 171.429% */
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  column-gap: 8px;
  height: 46px;
  border-radius: 4px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);

  &.active {
    border-radius: 4px;
    background: $color-light-purple-70 !important;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  }

  &.purple {
    background-color: $color-light-purple;
    color: $color-white;
    &:hover {
      background-color: $color-light-purple-70 !important;;
    }
  }

  &.light-purple {
    background-color: $color-light-purple-70;
    color: $color-white;
  }

  &.dark-purple {
    background-color: $color-light-purple-30;
    color: $color-white;
  }

  &.contrast {
    background-color: #ffffff;
    color: $color-light-purple;
  }

  &.size-sm {
    height: 27px;
    font-size: 10px;
  }

  &.w-100 {
    width: 100%;
  }

  &.flat {
    background-color: $color-light-purple-30;
    color: $color-white;
    box-shadow: none;
    padding: 8px;
    column-gap: 4px;
    height: 44px;
  }

  @media (max-width: 576px) {
    padding: 8px 15px;
  }

}

.csn-flex-items-center {
  display: flex;
  align-items: center;
  column-gap: 10px;
}

/* custom icons */
.csn-icon {

  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  width: 30px;
  height: 30px;

  &.size-30 {
    width: 30px;
    height: 30px;
    background-size: 30px 30px;
  }

  &.size-15 {
    width: 15px;
    height: 15px;
    background-size: 15px 15px;
  }

  &.size-20 {
    width: 20px;
    height: 20px;
    background-size: 20px 20px;
  }

  &.size-50 {
    width: 50px;
    height: 50px;
    background-size: 50px 50px;
  }

  &.size-100 {
    width: 100px;
    height: 100px;
    background-size: 100px 100px;
  }

  &.horseshoe-icon {
    background-image: url('assets/img/horseshoe.svg');
  }

  &.leaf-icon {
    background-image: url('assets/img/leaf.svg');
  }

  &.money-icon {
    background-image: url('assets/img/money.svg');
  }

  &.gold-account-icon {
    background-image: url('assets/img/account-gold.svg');
  }

  &.silver-account-icon {
    background-image: url('assets/img/account-silver.svg');
  }

  &.bronze-account-icon {
    background-image: url('assets/img/account-bronze.svg');
  }

  &.chip-icon {
    background-image: url('assets/img/chip.png');
  }

  &.playing-cards-icon {
    background-image: url('assets/img/playing-cards.svg');
  }

  &.fishki-icon {
    background-image: url('assets/img/fishki.svg');
  }

  &.coins-icon {
    background-image: url('assets/img/coins.svg');
  }

  &.diamond-icon {
    background-image: url('assets/img/diamond.svg');
  }

  &.noname-icon {
    background-image: url('assets/img/noname.svg');
  }

}

.visa-icon {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  width: 144px;
  height: 45px;
  background-image: url('assets/img/visa.svg');
}

/* html form controls */

.csn-form-control-inline {
  display: flex;
  align-items: center;
  //gap: 10px;
}

.csn-form-group {

  margin-bottom: 10px;
  text-align: left;

  &.text-align-center {
    text-align: center;
  }

  &.inline-group {
    display: inline-flex;
    align-items: center;
    flex-wrap: wrap;
  }

  .csn-label {
    color: #FFF;
    font-family: 'Unbounded', serif;
    font-size: 10px;
    font-style: normal;
    font-weight: 900;
    line-height: 24px; /* 240% */
    margin-bottom: 0 !important;
    padding-left: 5px;
  }

  .csn-form-control {

    font-family: 'Unbounded', serif;
    border-radius: 5px;
    border: 1px solid $color-light-purple-30;
    background: $color-light-purple-30;
    height: 40px;
    flex-shrink: 0;
    color: #FFF;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 240% */
    padding: 12px 9px;
    width: 100%;

    &.size-sm {
      height: 27px;
    }

    &.grey {
      border-radius: 5px;
      background-color: rgba(0, 0, 0, 0.25);
    }

    &.w-auto {
      width: auto;
    }

    &.flex-1 {
      flex: 1;
    }

  }

  .csn-form-checkbox {

    display: flex;
    align-items: center;
    column-gap: 10px;

    .csn-form-control {
      height: 25px;
      width: 25px;
    }

  }

  .csn-datepicker-toggle {
    .mat-mdc-icon-button {
      height: 30px;
      width: 30px;
      padding: 0;
      margin: 0;
      color: $color-light-purple;
    }
  }

  select {
    &.csn-form-control {
      padding: 0;
    }
  }

  input {
    &::placeholder {
      color: #ffffff !important;
    }
  }

  button {
    border-width: 0 !important;
  }

}

form {
  margin: 0 !important;
}

.flex-1 {
  flex: 1 !important;
}

.csn-alert {
  padding: 50px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.error {
  color: #F00;
  font-family: 'Unbounded', sans-serif;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 333.333% */
}

/* odometer on home page */
.odometer.odometer-auto-theme, .odometer.odometer-theme-default {
  color: #FFF;
  font-family: 'Unbounded' !important;
  font-size: 40px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  letter-spacing: 0.8px;

  @media (max-width: 992px) {
    //color: $color-light-purple-70;
    font-size: 15px;
  }

}

.cdk-overlay-container {
  z-index: 1020 !important;
}

.cdk-overlay-pane:not(.mat-mdc-autocomplete-panel-above) .mdc-menu-surface.mat-mdc-autocomplete-panel {
  background-color: $color-light-purple;
}

/* dialogs temporary please remove after testing */
.mat-mdc-dialog-container .mdc-dialog__surface {
  color: #000;
}

.mdc-card {
  padding: 10px;
}

// Define a custom typography config that overrides the font-family as well as the
// `headlines` and `body-1` levels.
$custom-typography: mat.define-legacy-typography-config(
  $font-family: 'Unbounded',
  $headline: mat.define-typography-level(14px, 16px, 400),
  $title: mat.define-typography-level(1.5rem, 2.3rem, 500),
  $subheading-1: mat.define-typography-level(20px, 24px, 900),
  $subheading-2: mat.define-typography-level(18px, 22x, 900),
  $body-1: mat.define-typography-level(14px, 16px, 400));

@include mat.legacy-typography-hierarchy($custom-typography);
@include mat.legacy-checkbox-typography($custom-typography);
@include mat.all-legacy-component-typographies($custom-typography);
@include mat.legacy-core();

/*** Admin ***/
@import '../../../node_modules/primeng/resources/themes/lara-light-blue/theme.css';
@import '../../../node_modules/primeng/resources/primeng.min.css';
@import '../../../node_modules/primeicons/primeicons.css';
@import '../../../node_modules/primeflex/primeflex.css';
@import "admin";
