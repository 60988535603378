.admin-page-container {

  h2 {
    text-transform: uppercase;
    margin: 5px 0;
  }

  a {
    color: #000;
  }

  .admin-header-text {
    font-size: 1.1em;
    margin-left: 15px;
    margin-right: 15px;
  }

  .asterisk:after {
    content: "*";
    color: red;
    margin-left: 5px;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .p-dropdown-export {
    margin: 10px 0;
  }

  .link-item {
    text-decoration: underline;
    cursor: pointer;
  }

  .w-100 {
    width: 100%;
  }

  .ui-dialog-buttonpane {
    padding-top: 20px;
  }

  /* overwrite pi rules */
  .p-sidebar .p-sidebar-content {
    padding: 0 20px;
    background-color: #3C4962;
  }

  .p-menu {
    border-width: 0;
    background-color: #3C4962;
  }

  .p-sidebar-left {
    width: auto;
    background-color: #3C4962;
  }

  .p-menu .p-menuitem-link {
    color: #fff;
  }

  .p-menu .p-menuitem-link .p-menuitem-text {
    color: #fff;
  }

  .p-menu .p-menuitem-link .p-menuitem-icon {
    color: #fff;
  }

  .p-toolbar {
    margin: 10px 0;
    padding: 10px 15px;
    background-color: #fff;
    button {
      margin: 2px;
    }
  }

  .p-inline-message {
    width: 100%;
  }

  .p-dropdown {
    width: 100%;
  }

  .p-dialog {
    width: 50vw;
  }

  .p-grid {
    // align-items: center;
    margin: 0;
  }

  .p-datatable .p-datatable-tbody > tr > td {
    padding: 0.5rem;
  }

  .p-button {
    padding: 0.5rem 0.6rem;
  }

  @media screen and (max-width: 1200px) {
    .p-dialog {
      width: 960px;
    }
  }

  @media screen and (max-width: 992px) {
    .p-dialog {
      width: 75vw;
    }
  }

  @media screen and (max-width: 768px) {
    .p-dialog {
      width: 640px;
    }
  }

  @media screen and (max-width: 576px) {
    .p-dialog {
      width: 95vw;
    }
  }

}
